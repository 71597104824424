if (!document.adgilebuild) {
  document.adgilebuild = document.adgilebuild || 'build-1784-fa7fcae4d1f943c06f4fb9191cfec3f25fd1bc8b';
  console.log(document.adgilebuild);
}
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from 'components/Auth/react-auth0-wrapper';
import { switchLocale, getLocale } from './locale';
import { CacheProvider } from 'rest-hooks';
import PrivateBoundary from 'components/Auth/PrivateBoundary';

// Keep this in sync with register/register/FetchInterceptor.svelte
const ACTIVE_SERVERS = {
  localhost: 'http://localhost:63785',
  'dev.catalyst.adgile.com': 'https://adgile-portalapi-dev.azurewebsites.net',
  'stage.catalyst.adgile.com': 'https://adgile-portalapi-stage.azurewebsites.net',
  'live.catalyst.adgile.com': 'https://adgile-portalapi.azurewebsites.net',
  'catalyst.adgile.com': 'https://adgile-portalapi.azurewebsites.net',
  'au.catalyst.adgile.com': 'https://adgile-portalapi.azurewebsites.net',
  'nz.catalyst.adgile.com': 'https://adgile-portalapi-nz.azurewebsites.net',
  'ca.catalyst.adgile.com': 'https://adgile-portalapi-ca.azurewebsites.net',
};

const PREFERRED_DOMAIN = 'catalyst.adgile.com';

const DOMAIN_PREFIXES = ['dev.', 'stage.', 'live.', 'au.', 'nz.', 'ca.'];

/**
 * @returns {string|null} the url to redirect to or null if no redirect is needed
 * @param {string} inputHostname
 */
function getPreferredUrl(inputHostname) {
  let hostname = inputHostname ? inputHostname.toLowerCase() : '';
  if (hostname === 'localhost' || hostname === PREFERRED_DOMAIN) {
    return null;
  }
  if (!hostname.endsWith(PREFERRED_DOMAIN)) {
    // definitely redirect to correct prefix
    for (const prefix of DOMAIN_PREFIXES) {
      if (hostname.startsWith(prefix)) {
        return prefix + PREFERRED_DOMAIN;
      }
    }
  } else {
    for (const prefix of DOMAIN_PREFIXES) {
      if (hostname.startsWith(prefix)) {
        // correct domain and prefix
        let preferred = prefix + PREFERRED_DOMAIN;
        if (preferred == hostname) {
          return null;
        } else {
          return preferred;
        }
      }
    }
  }
  // If we get to here our prefix is unknown let the login handle the prefix
  return PREFERRED_DOMAIN;
}

let redirect = getPreferredUrl(window.location.hostname);
if (redirect) {
  console.log('REDIRECTING TO', redirect);
  location.host = redirect;
} else {
  // App
  const App = lazy(() => import('./App'));

  Object.fromEntries = Object.fromEntries || ((arr) => Object.assign({}, ...Array.from(arr, ([k, v]) => ({ [k]: v }))));

  switchLocale(getLocale());

  // A function that routes the user to the right place
  // after login
  const onRedirectCallback = (appState) => {
    window.history.replaceState({}, document.title, appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  };

  let api_server = ACTIVE_SERVERS[window.location.hostname.toLowerCase()];
  if (api_server) {
    window.API_SERVER = api_server;
  }
  console.warn('API', api_server, 'from', window.location.hostname);

  ReactDOM.render(
    <Auth0Provider domain="adgile.au.auth0.com" client_id={process.env.CLIENT_ID} audience={window.API_SERVER} redirect_uri={window.location.origin} onRedirectCallback={onRedirectCallback}>
      <PrivateBoundary>
        <CacheProvider>
          <Suspense
            fallback={
              <div className="splash">
                <div>
                  <img width="300px" src={'/images/logos/adgile-catalyst-light.png'} />
                </div>
                <div>LOADING...</div>
              </div>
            }
          >
            <App />
          </Suspense>
        </CacheProvider>
      </PrivateBoundary>
    </Auth0Provider>,
    document.getElementById('root')
  );
}

navigator.serviceWorker.register(new URL('zipworker.js', import.meta.url), { type: 'module' });
